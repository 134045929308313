import axios from "axios";

export const getKycDetails = async (identifier) => {
  const apiResponse = await axios({
    method: "get",
    url: `${process.env.REACT_APP_LINK_KYC_URL}/v1/link-kyc/details`,
    headers: {
      identifier,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });
  return apiResponse.data.result;
};

export const completeKYCFlow = async (identifier, status) => {
  const apiResponse = await axios({
    method: "post",
    url: `${process.env.REACT_APP_LINK_KYC_URL}/v1/link-kyc/complete`,
    data: { status, identifier },
  });
  return apiResponse.data.result;
};
